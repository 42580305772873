/* eslint-disable react-hooks/exhaustive-deps */
import { useLoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import Checkboxlist from '~/components/elements/post-overview/CheckboxList'
import ClearFilters from '~/components/elements/post-overview/ClearFilters'
import { SomethingWentWrong } from '~/components/elements/post-overview/SomethingWentWrong'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import { type SearchBucketAggregation } from '~/services/elastic.server'
import type { PageBodyData } from '~/templates/page'
import { getTotal } from './PostsVacancies'
import Candidate from './post-types/Candidate'
import { Fragment, useEffect, useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import { useInView, AnimatePresence, motion } from 'framer-motion'
import clsx from 'clsx'
import { useSessionStorage } from 'usehooks-ts'
import Form from '~/components/elements/Form'
import ModalFullScreen from '~/components/elements/ModalFullScreen'
import Overview from '~/components/elements/post-overview/Overview'
import useIsMobile from '~/hooks/useIsMobile'
import CandidatesAlert from '~/components/elements/post-overview/CandidatesAlert'
import ValhallaSearchCandidates from '~/components/elements/ValhallaSearchCandidates'

export default function PostsCandidates({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const containerRef = useRef(null)
  const containerIsVisible = useInView(containerRef, { once: true })
  const { setScrollable } = useLosseLayout()
  const { middleware } = useLoaderData<PageBodyData>()
  const [selectedCandidates] = useSessionStorage('selected-candidates', [])
  const [showPopup, setShowPopup] = useState(true)

  useEffect(() => {
    if (selectedCandidates.length === 0 && showPopup) setShowPopup(false)
  }, [selectedCandidates, showPopup])

  useEffect(() => {
    if (showPopup) setScrollable(false)
    if (!showPopup) setScrollable(true)
  }, [showPopup])

  if (middleware?.candidates.status === 'failed') {
    return <SomethingWentWrong />
  }

  const total = getTotal(middleware?.candidates?.data?.hits?.total)

  return (
    <>
      <section
        data-component="PostsCandidates"
        className={clsx('header--margin--top--negative', containerIsVisible ? 'opacity-100' : 'opacity-0')}
        ref={containerRef}
      >
        <div className="bg-site-base pb-5 lg:pb-[80px] header--padding--top">
          <div className="container py-12 animate-fade-up">
            <h1 className="text-white title--semi-large">{fields?.title}</h1>
          </div>
        </div>

        <div className="container relative grid grid-cols-1 lg:grid-cols-12 gap-8 -mt-[60px]">
          <div className="lg:col-span-3">
            <div className="bg-site-accent p-6 lg:px-7 lg:pt-6 lg:pb-10 max-lg:hidden">
              <h2 className="title--small">Hoe werkt het?</h2>
              <div className="list--stripe">
                <ul className="mt-4 children-strong:font-bold children-li:before:bg-white max-lg:text-sm">
                  <li>
                    <span>
                      Selecteer professionals met de knop <strong>+ Toevoegen</strong>
                    </span>
                  </li>
                  <li>Na de selectie verschijnt een pop-up in beeld</li>
                  <li>Ontvang meer informatie per mail over de pro’s</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lg:col-span-9">
            <ValhallaSearchCandidates className="mb-6" />
            <Content className="">{fields?.description}</Content>
          </div>
        </div>

        <Overview
          // contentChildren={}
          filtersChildren={
            <>
              <div className="flex items-center justify-between mb-6 lg:mb-12">
                <h2 className="max-lg:text-3xl title--small">Filters</h2>
                <ClearFilters filters={['Vakgebieden', 'Functies']} />
              </div>
              <div>
                <Checkboxlist
                  field="Vakgebieden"
                  aggregation={middleware?.candidates.data.aggregations.functionGroup as SearchBucketAggregation}
                  className="animate-delay-500"
                />
                <Checkboxlist
                  field="Functies"
                  aggregation={middleware?.candidates.data.aggregations.function as SearchBucketAggregation}
                  className="animate-delay-[600ms]"
                />
              </div>
              <CandidatesAlert className="mt-12 max-lg:hidden" />
            </>
          }
          sortOptions={['Laatst toegevoegd', 'Eerst toegevoegd']}
          total={total}
          title={`pro${total !== 1 ? "'s" : ''}`}
          hits={middleware?.candidates?.data?.hits?.hits?.length}
          limit={middleware?.candidates?.limit}
        >
          <div className="grid mt-6 gap-6">
            {middleware?.candidates?.data?.hits?.hits?.map((hit, index) => (
              <Fragment key={hit._id}>
                <Candidate source={hit._source} className="animate-fade-up" style={{ animationDelay: `${index * 100 + 250}ms` }} />
              </Fragment>
            ))}
          </div>
        </Overview>
      </section>

      <AnimatePresence>{selectedCandidates.length > 0 && <SelectedCandidatesSlideIn setShowPopup={setShowPopup} />}</AnimatePresence>

      <ModalFullScreen show={showPopup} setShow={setShowPopup}>
        <SelectedCandidatesModal fields={fields} showPopup={showPopup} />
      </ModalFullScreen>
    </>
  )
}

function SelectedCandidatesSlideIn({ setShowPopup }: { setShowPopup: (value: boolean) => void }) {
  const [selectedCandidates, setSelectedCandidates] = useSessionStorage('selected-candidates', [])
  const isMobile = useIsMobile()

  return (
    <motion.div
      initial={isMobile ? { opacity: 0, y: '100%' } : { opacity: 0, x: '100%' }}
      animate={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, x: 0 }}
      exit={isMobile ? { opacity: 0, y: '100%' } : { opacity: 0, x: '100%' }}
      transition={{ duration: 0.2 }}
      className="fixed max-sm:justify-between max-sm:flex max-sm:gap-2 max-sm:bottom-0 sm:right-0 max-sm:left-2 max-sm:right-2 max-sm:rounded-t-md sm:top-2/3 sm:-translate-y-1/2 z-40 bg-white sm:rounded-l-md p-4 text-site-base block--shadow"
    >
      <div className="px-4 py-3 bg-site-grey-light rounded-md sm:text-lg font-bold text-center max-sm:flex-grow max-sm:flex max-sm:items-center max-sm:justify-center">
        <div>
          {selectedCandidates.length} professional{selectedCandidates.length === 1 ? '' : 's'}
        </div>
        <div className="text-site-base/40 text-sm font-bold block -mt-[2px] group-hover:text-white/50 max-sm:hidden">geselecteerd</div>
      </div>
      <div className="max-sm:flex max-sm:items-center">
        <button
          type="button"
          onClick={() => {
            setShowPopup(true)
          }}
          className="btn--conversion sm:mt-4 block"
        >
          Ontvang<span className="max-sm:hidden"> informatie</span>
        </button>
        <div className="flex justify-center max-sm:hidden">
          <button
            type="button"
            className="mt-2 text-xs font-bold text-site-base hover:bg-site-grey-light/50 hover:underline px-1 rounded-md"
            onClick={() => {
              setSelectedCandidates([])
            }}
          >
            Wis selectie
          </button>
        </div>
      </div>
    </motion.div>
  )
}

function SelectedCandidatesModal({ fields, showPopup }: { fields: Page_Flexcontent_Flex_Posts; showPopup: boolean }) {
  const [selectedCandidates, setSelectedCandidates] = useSessionStorage('selected-candidates', [])
  const [formSend, setFormSend] = useState(false)

  useEffect(() => {
    if (!showPopup && formSend) {
      setFormSend(false)
      setSelectedCandidates([])
    }
  }, [showPopup])

  return (
    <div className="container max-w-5xl">
      <div className="grid md:grid-cols-2 gap-10 lg:gap-20">
        <div className="pt-6">
          <div className="flex">
            <h2 className="title--medium relative text-white">
              {fields?.formtitle}
              <span className="block absolute -left-4 -top-10 w-1/2 h-[70px] bg-site-accent -z-10" />
            </h2>
          </div>
          <Content className="mt-6 lg:mt-12" theme="dark">
            <p>Je hebt de volgende CV{selectedCandidates.length === 1 ? '' : "'s"} geselecteerd</p>
          </Content>
          <div className="mt-4 lg:mt-6 flex flex-wrap gap-3">
            {selectedCandidates.map((candidate) => (
              <button
                type="button"
                key={candidate}
                className="label--grey--small flex items-center gap-2 btn--pseudo btn--pseudo--conversion"
                onClick={() => {
                  setSelectedCandidates(selectedCandidates.filter((_candidate) => _candidate !== candidate))
                }}
                disabled={formSend}
              >
                {!formSend && (
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="01 align center">
                      <path
                        id="Vector"
                        d="M8.69234 1.19173L7.80859 0.307983L4.50047 3.61611L1.19234 0.307983L0.308594 1.19173L3.61672 4.49986L0.308594 7.80798L1.19234 8.69173L4.50047 5.38361L7.80859 8.69173L8.69234 7.80798L5.38422 4.49986L8.69234 1.19173Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                )}
                CV Nr {candidate}
              </button>
            ))}
          </div>
        </div>
        <div className="lg:pr-12">
          {!formSend && (
            <Content theme="dark" className="mb-6">
              {fields?.formdescription}
            </Content>
          )}

          <Form
            defaultFields={{ input_6: selectedCandidates.join(', ') }}
            data={fields.form}
            onSubmitDone={() => {
              setFormSend(true)
            }}
          />
        </div>
      </div>
    </div>
  )
}
