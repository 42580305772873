/* eslint-disable react-hooks/exhaustive-deps */
import { Form, useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'

export default function ValhallaSearchCandidates({
  type = 'normal',
  className,
  popularSearches = []
}: {
  type?: 'normal' | 'gradient'
  className?: string
  popularSearches?: string[]
}) {
  const [searchParams] = useSearchParams()
  const [zipcode, setZipcode] = useState(searchParams.get('Postcode') || '')
  const [travelTime, setTravelTime] = useState(searchParams.get('Reistijd') || '')

  return (
    <Form action="/beschikbare-professionals/" method="get" className={clsx('w-full', className)}>
      <div
        className={clsx(
          type === 'gradient' && 'rounded-t-2xl md:rounded-md bg-site-grey md:gradient--background backdrop-blur items-start',
          type === 'normal' && 'bg-site-grey max-md:rounded-md items-end',
          'grid grid-cols-8 gap-4 p-6 sm:p-6 lg:p-8 w-full md:animate-fade-up'
        )}
      >
        <label className="flex flex-col col-span-12 md:col-span-2 md:pl-10 md:-mr-10" htmlFor="Postcode">
          <span className="font-bold mb-1 lg:text-lg">
            <span className="max-md:inline-block max-lg:hidden">Jouw p</span>
            <span className="max-md:hidden lg:hidden">P</span>ostcode
          </span>
          <input
            type="text"
            placeholder="Bijv. 3751AA"
            name="Postcode"
            id="Postcode"
            className="form-duuf-input !text-base"
            value={zipcode}
            onChange={(event) => {
              setZipcode(event.target.value)
            }}
          />
        </label>

        <label className="flex flex-col col-span-12 md:col-span-3 md:pl-10 md:-mr-5" htmlFor="Reistijd">
          <span className="font-bold mb-1 lg:text-lg">Max. reistijd</span>

          <select
            name="Reistijd"
            id="Reistijd"
            className={clsx(
              'form-duuf-select__control form-duuf-input bg-white !text-base select--icon',
              !travelTime && '!text-site-base/50'
            )}
            value={travelTime}
            placeholder="Kies een reistijd"
            onChange={(event) => {
              setTravelTime(event.target.value)
            }}
          >
            <option disabled hidden value="">
              Kies een reistijd
            </option>
            <option value={15}>15 min.</option>
            <option value={30}>30 min.</option>
            <option value={45}>45 min.</option>
            <option value={60}>60 min.</option>
            <option value={75}>1 uur en 15 min.</option>
            <option value={90}>1 uur en 30 min.</option>
            <option value={105}>1 uur en 45 min.</option>
            <option value={120}>2 uur</option>
          </select>
        </label>

        <div className={clsx(popularSearches.length > 0 && 'mt-[32px]', 'md:pl-5 max-md:mt-2 col-span-12 md:col-span-3')}>
          <button
            className="btn btn--conversion border-2 max-sm:w-full max-md:w-full md:!h-[36px] border-site-conversion md:!py-1 lg:w-[200px]"
            type="submit"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20.8013 18.8006L16.5768 14.576C17.728 13.168 18.294 11.3714 18.1578 9.55772C18.0215 7.74409 17.1934 6.05221 15.8446 4.83204C14.4959 3.61186 12.7298 2.95676 10.9117 3.00222C9.09348 3.04768 7.36232 3.79023 6.07628 5.07628C4.79023 6.36232 4.04768 8.09348 4.00222 9.91166C3.95676 11.7298 4.61186 13.4959 5.83204 14.8446C7.05221 16.1934 8.74409 17.0215 10.5577 17.1578C12.3714 17.294 14.168 16.728 15.576 15.5768L19.8006 19.8013C19.934 19.9303 20.1128 20.0016 20.2984 20C20.484 19.9984 20.6615 19.9239 20.7927 19.7927C20.9239 19.6615 20.9984 19.484 21 19.2984C21.0016 19.1128 20.9303 18.934 20.8013 18.8006ZM11.1002 15.7622C9.98033 15.7622 8.88564 15.4301 7.95453 14.808C7.02341 14.1858 6.2977 13.3015 5.86915 12.2669C5.44061 11.2323 5.32848 10.0939 5.54695 8.99557C5.76542 7.89724 6.30468 6.88837 7.09652 6.09652C7.88837 5.30468 8.89724 4.76542 9.99557 4.54695C11.0939 4.32848 12.2323 4.44061 13.2669 4.86915C14.3015 5.2977 15.1858 6.02341 15.808 6.95453C16.4301 7.88564 16.7622 8.98033 16.7622 10.1002C16.7605 11.6013 16.1634 13.0405 15.102 14.102C14.0405 15.1634 12.6013 15.7605 11.1002 15.7622Z"
                fill="white"
              />
            </svg>
            <span>
              Zoek<span className="max-md:inline-block max-lg:hidden">&nbsp;professionals</span>
            </span>
          </button>
        </div>
      </div>
    </Form>
  )
}
